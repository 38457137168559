/**
 * Created by @author Søren Tramm
 */
import React, { useMemo, useState } from 'react'
import { Button } from 'antd'
import { PhaseContentLabel } from '../../phase/PhaseContentLabel'
import { ProtocolQuestionnaireListItem } from './ProtocolQuestionnaireListItem'
import { Link } from 'react-router-dom'
import { LanguageISOCode, RESOURCE_STATUS } from '../../../Types'

type Props = {
  phase: any
  protocol: any
  language: LanguageISOCode
}

export const ProtocolOverviewListItem = ({ phase, protocol, language }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  const questionnaires = phase?.questionnaires || []
  //const visits = phase?.visits || []

  const toogle = () => {
    setIsOpen(!isOpen)
  }

  const mayBeEdited = useMemo(() => {
    return protocol?.status === RESOURCE_STATUS.DRAFT
  }, [protocol])

  return (
    <div className="row mb-4 pb-4  " style={{ boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)', background: '#FAFAFA' }}>
      <div
        className="col-12  py-2  d-flex align-items-center"
        style={{ boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.1)', background: '#6d897b' }}
      >
        <div className="ps-2 my-3 text-white" style={{ width: '25%' }}>
          <p className="p-mini opacity-50 ">{phase._id}</p>
          <h5 className="mb-1 fw-bold ">{phase.title}</h5>
        </div>

        <div className="ps-2 my-3 text-white " style={{ width: '15%' }}>
          <p className="p-mini opacity-50 ">duration</p>
          <p className="mb-1 fw-bold ">{phase.duration} days</p>
        </div>
        <div className="ps-2 my-3 text-white" style={{ width: '15%' }}>
          <p className="p-mini opacity-50 text-white">type</p>
          <p className="mb-1 fw-bold text-white">{phase.type}</p>
        </div>

        <div className="ps-2 my-3 text-white" style={{ width: '15%' }}>
          <p className="p-mini opacity-50 text-white">short compliance</p>
          <p className="mb-1 fw-bold text-white">{phase.shortCompliance ? phase.shortCompliance + ' days' : '-'} </p>
        </div>
        <div className="ps-2 my-3 text-white" style={{ width: '15%' }}>
          <p className="p-mini opacity-50 text-white">overall compliance</p>
          <p className="mb-1 fw-bold text-white">{phase.overallCompliance ? phase.overallCompliance + '%' : '-'}</p>
        </div>

        <div className="ps-2 my-3 text-white" style={{ width: '15%' }}>
          <p className="p-mini opacity-50 text-white">active</p>
          <p className="mb-1 fw-bold text-white">{phase.status}</p>
        </div>

        <div className="d-flex justify-content-end" style={{ width: '30%' }}>
          {phase.status === RESOURCE_STATUS.ACTIVE ? (
            <Link to={`./clone-phase/${phase._id}`}>
              <Button className="me-2 border-0 text-white" style={{ background: '#5b7066' }} type="default">
                Clone phase
              </Button>
            </Link>
          ) : (
            <Link to={`./view-phase/${phase._id}`}>
              <Button type="text" className="text-white">
                View phase
              </Button>
            </Link>
          )}
        </div>
      </div>

      {isOpen ? (
        <>
          {/*QUESTIONNAIRES*/}
          <PhaseContentLabel label="QUESTIONNAIRES">
            {mayBeEdited ? (
              <div className="col-8 d-flex justify-content-end  ">
                <Link to={`phase/${phase._id}/create-questionnaire/`}>
                  <Button type="text" className="py-0 my-0">
                    <p className="p-small text-primary">Add questionnaire</p>
                  </Button>
                </Link>
              </div>
            ) : null}
          </PhaseContentLabel>

          <div className="col-12 px-4" style={{}}>
            <div className="row" style={{}}>
              {questionnaires.length !== 0 ? (
                questionnaires.map((ques, qIndex) => {
                  return (
                    <ProtocolQuestionnaireListItem
                      key={qIndex}
                      item={ques}
                      to={`./${phase._id}`}
                      mayClone={phase.status !== RESOURCE_STATUS.ACTIVE}
                      language={language}
                    />
                  )
                })
              ) : (
                <NoQuestionnaire to={''}></NoQuestionnaire>
              )}
            </div>
          </div>

          {/*VISITS*/}
          {/*     <PhaseContentLabel label="VISITS">
            {mayBeEdited ? (
              <div className="col-8 d-flex justify-content-end my-1">
                <Link to={`add-visit/${phase._id}`}>
                  <Button type="default">Add visit</Button>
                </Link>
              </div>
            ) : null}
          </PhaseContentLabel>

          {visits.length !== 0 ? <ProtocolVisitList visitArr={visits} protocol={protocol} /> : <NoVisit />}*/}
        </>
      ) : (
        <div className="col-12 d-flex justify-content-center">
          <Button onClick={toogle} className="mt-4" type="primary">
            Show phase
          </Button>
        </div>
      )}
    </div>
  )
}

/*const NoVisit = () => {
  return (
    <div className="col-12 px-4 mb-3">
      <div className="row pb-2 border rounded">
        <div className="col-12">
          <div className="w-100 pt-3 pb-2 px-2 d-flex justify-content-center align-items-center">
            <p className="me-2 opacity-75">No visits added to the protocol for this phase, yet</p>
          </div>
        </div>
      </div>
    </div>
  )
}*/

const NoQuestionnaire = ({ to }) => {
  return (
    <div className="col-12 px-4 mb-3">
      <div className="row pb-2 border rounded">
        <div className="col-12">
          <div className="w-100 pt-3 pb-2 px-2 d-flex justify-content-center align-items-center">
            <p className="me-2 opacity-75">No questionnaires added to the protocol for this phase, yet </p>
          </div>
        </div>
      </div>
    </div>
  )
}
