/**
 * Created by @author Søren Tramm on 21/08/2023.
 */
import { BODY_PART_LABELS } from '../../../../constants'

const baseConfigurationClassification = {
  id: 'options.classification',
  type: 'TEXT_TRANSLATION',
  placeholder: 'Classification',
  label: '',
  prefix: '',
  postfix: '',
  value: { en: '' },
  validation: { required: true },
  errorMsg: 'Value is required',
}
const baseConfigurationIntensity = {
  id: 'options.intensity',
  type: 'TEXT_TRANSLATION',
  placeholder: 'Intensity',
  label: '',
  prefix: '',
  postfix: '',
  value: { en: '' },
  validation: { required: true },
  errorMsg: 'Value is required',
}
const nprs11Point5LabelQuestionConfigBase = [
  {
    ...baseConfigurationClassification,
    label: 'Classification labels',
    id: baseConfigurationClassification.id + '.0',
    value: { en: 'None' },
  },
  {
    ...baseConfigurationClassification,
    id: baseConfigurationClassification.id + '.1',
    value: { en: 'Mild' },
  },
  {
    ...baseConfigurationClassification,
    id: baseConfigurationClassification.id + '.2',
    value: { en: 'Moderate' },
  },
  {
    ...baseConfigurationClassification,
    id: baseConfigurationClassification.id + '.3',
    value: { en: 'Severe' },
  },
  {
    ...baseConfigurationClassification,
    id: baseConfigurationClassification.id + '.4',
    value: { en: 'Worst pain imaginable' },
  },
  {
    ...baseConfigurationIntensity,
    label: 'Intensity values',
    id: baseConfigurationIntensity.id + '.0',
    value: { en: '0' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.1',
    value: { en: '1' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.2',
    value: { en: '2' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.3',
    value: { en: '3' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.4',
    value: { en: '4' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.5',
    value: { en: '5' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.6',
    value: { en: '6' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.7',
    value: { en: '7' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.8',
    value: { en: '8' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.9',
    value: { en: '9' },
  },
  {
    ...baseConfigurationIntensity,
    id: baseConfigurationIntensity.id + '.10',
    value: { en: '10' },
  },
] as const

const getBodyPartLabel = (value: string) => {
  return BODY_PART_LABELS[value] ?? 'Missing label'
}

export const getNprs11Point5LabelConfig = (bodyParts: string[]) => {
  return [
    {
      id: 'correlations.bodypart',
      type: 'DROPDOWN',
      placeholder: 'Select a body part',
      label: 'What part of the body does this question relate to?',
      prefix: '',
      postfix: '',
      value: '',
      options: bodyParts?.map((value) => {
        return { value, label: getBodyPartLabel(value) }
      }),
      validation: { required: true },
      errorMsg: 'Body part is required',
      disabled: true,
    },
    ...nprs11Point5LabelQuestionConfigBase,
  ] as const
}
