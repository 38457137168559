export const questionnaireCommonConfig = [
  {
    id: 'internalTitle',
    type: 'TEXT',
    placeholder: 'Add internal title',
    label: 'Internal Title',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Internal title is required',
  },
  {
    id: 'name',
    type: 'TEXT_TRANSLATION',
    placeholder: 'Add title',
    label: 'Title',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Name is required',
  },
] as const

export const questionnaireOnboardingConfig = {
  id: 'onboarding',
  type: 'SWITCH',
  placeholder: '',
  label: 'Is onboarding',
  prefix: '',
  postfix: '',
  value: false,
  validation: { required: false },
  errorMsg: 'Onboarding flag is required',
} as const

export const questionnaireReminderConfig = [
  {
    id: 'notifications.reminders.start',
    type: 'TEXT_TRANSLATION',
    placeholder: 'Notification text',
    label: 'Reminder on activation',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Reminder text is required',
  },
  {
    id: 'notifications.reminders.end',
    type: 'TEXT_TRANSLATION',
    placeholder: 'Notification text',
    label: 'End reminder',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Reminder text is required',
  },
] as const
