/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/06/2024.
 */
import React, { Fragment, useRef, useState } from 'react'
import { useProtocolPages } from '../../hooks/useProtocolPages'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import { Button, Input, InputRef, Popover, Tabs } from 'antd'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { destroy, post, put } from '../../service/API'
import Spinner from '../../components/spinner/Spinner'
import { ErrorView } from '../../components/error/ErrorView'
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary'
import { RESOURCE_STATUS } from '../../Types'

type Props = {
  activeProtocolId: string
}

export const ProtocolPagesOverview = ({ activeProtocolId }: Props) => {
  const { protocolId, projectId, pageId } = useParams() as { protocolId: string; projectId: string; pageId: string }
  const { data, mutate } = useProtocolPages(projectId, protocolId, pageId)
  const [loading, setLoading] = useState(false)

  const errorHandler = useErrorHandler()
  const isActiveProtocol = protocolId === activeProtocolId

  //
  // after cloning all the pages shown are active. They need to be cloned eg. DRAFT - before they can be edited
  //

  const pagesIsActive = data.every((page) => {
    return page.status === RESOURCE_STATUS.ACTIVE
  })

  //
  // A guard to make sure all pages are active on a active protocol
  //
  if (isActiveProtocol && !pagesIsActive) {
    ///throw new Error('All pages must have status ACTIVE on a active protocol')

    console.log(' BREAKKKKKKKKK ')
  }

  const onChange = (value) => {
    console.log('ProtocolPagesOverview > value = ', value)
  }

  //const pages = data
  const firstLang = Object.keys(data)[0] || null
  const tabsMenu = firstLang ? data[firstLang].content : null

  const items = !tabsMenu
    ? []
    : Object.keys(tabsMenu).map((pageName, i) => {
        const id = String(i + 1)

        const k = Object.keys(data).map((key) => {
          return data[key] //.content[pageName]
        })

        const pageTitle = pageName.charAt(0).toUpperCase() + pageName.slice(1)

        return {
          label: pageTitle.replace('_', ' '),
          key: id + '_' + protocolId,
          children: (
            <TransSub data={k} pageName={pageName} mutate={mutate} isActiveProtocol={isActiveProtocol}></TransSub>
          ),
        }
      })

  // this is just to get the name of the pages eg. tabs

  const createPageNoText = () => {
    createPage(false)
  }
  const createPageWithText = () => {
    createPage(true)
  }
  const createPage = (useDefault: boolean) => {
    if (isActiveProtocol) {
      return
    }

    setLoading(true)
    const p = ENDPOINTS.TRANSLATION_PAGES.ACTIONS.CREATE_TRANSLATION_PAGES(projectId, protocolId, pageId)

    return post(p, { useDefault: useDefault })
      .then((result) => {
        console.log('ProtocolPagesOverview > result = ', result)
        setLoading(false)
        mutate()
        return result
      })
      .catch((error) => {
        setLoading(false)
        console.log('ProtocolPagesOverview > error = ', error)
        errorHandler(error)
        //return Promise.reject(error)
      })
  }

  const clonePage = () => {
    if (isActiveProtocol && pagesIsActive === true) {
      return
    }

    setLoading(true)
    const p = ENDPOINTS.TRANSLATION_PAGES.ACTIONS.CLONE_TRANSLATION_PAGES(projectId, protocolId)

    //
    // Clone pages - each page contains a language, so for a project with 3 languages we need to clone all of them
    //
    const clonePages = data.map((page) => {
      return page._id
    })

    return put(p, { pageIds: clonePages, pagename: pageId })
      .then((result) => {
        console.log('ProtocolPagesOverview > result = ', result)
        setLoading(false)
        mutate()
        return result
      })
      .catch((error) => {
        setLoading(false)
        console.log('ProtocolPagesOverview > error = ', error)
        errorHandler(error)
        //return Promise.reject(error)
      })
  }

  const deletePage = () => {
    const p = ENDPOINTS.TRANSLATION_PAGES.ACTIONS.DELETE_TRANSLATION_PAGES(projectId, protocolId)
    setLoading(true)
    const ids = data.length > 0 ? data.map((page) => page._id) : []

    const obj = { pageIds: ids }

    return destroy(p, obj)
      .then((result) => {
        setLoading(false)
        mutate()

        return result
      })
      .catch((error) => {
        setLoading(false)
        console.log('ProtocolPagesOverview > error = ', error)
        errorHandler(error)
        //return Promise.reject(error)
      })
  }

  return (
    <div className="row" style={{}}>
      <div className="col-12 mt-3">
        <div
          className="bg-white px-4 py-4"
          style={{
            border: '1px solid ',
            borderColor: '#CFD3D7 ',
            boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.08)',
            borderTop: '6px solid #567163',
          }}
        >
          {tabsMenu ? (
            <>
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <h4 className="mt-2">{pageId}</h4>
                  <div className="ms-2 ">
                    <div
                      className=" px-2 rounded-1 "
                      style={{ paddingTop: 1.5, paddingBottom: 1.5, background: pagesIsActive ? '#efefef' : '#cae8c1' }}
                    >
                      <p className="fw-bold p-small opacity-75">{pagesIsActive ? 'Active' : 'Draft'}</p>
                    </div>
                  </div>
                </div>
                {!isActiveProtocol ? (
                  <>
                    {pagesIsActive ? (
                      <Button onClick={clonePage} className="me-2" type="primary" loading={loading}>
                        Clone page
                      </Button>
                    ) : (
                      <Button onClick={deletePage} className="me-2" type="default" loading={loading}>
                        Delete page
                      </Button>
                    )}
                  </>
                ) : null}
              </div>
              <ErrorBoundary FallbackComponent={ErrorView}>
                <Tabs className="mt-5" defaultActiveKey="1" items={items} onChange={onChange} />
              </ErrorBoundary>
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <div className="text-center my-4">
                <h4 className="mb-2 ">{pageId}</h4>
                {isActiveProtocol ? (
                  <p className="mb-3 p-small">You are viewing an active protocol, which does not allow changes</p>
                ) : (
                  <p className="mb-3 p-small">This page has not been created</p>
                )}
                {!isActiveProtocol ? (
                  <div className="">
                    <Button onClick={createPageWithText} className="me-2" type="primary" loading={loading}>
                      Create with default text
                    </Button>
                    <Button onClick={createPageNoText} type="default" loading={loading}>
                      Create empty page
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const TransSub = ({ data, pageName, mutate, isActiveProtocol }) => {
  //
  // data is an array with an array for each language
  // we pull the first item to get the keys
  //

  const first = data[0].content[pageName]

  return (
    <div className="">
      {Object.keys(first).map((key, i) => {
        const indexKey = i + key + pageName
        //console.log('ProtocolPagesOverview > indexKey = ', indexKey)

        return (
          <div key={indexKey} className="mb-3 ">
            <TransItem
              data={data}
              fieldKey={key}
              blockName={pageName}
              mutate={mutate}
              isActiveProtocol={isActiveProtocol}
            ></TransItem>
          </div>
        )
      })}
    </div>
  )
}

const Timer = (delay: number = 500) => {
  return new Promise((resolve, reject) => setTimeout(resolve, delay))
}

const TransItem = ({ data, blockName, fieldKey, mutate, isActiveProtocol }) => {
  const { protocolId, projectId, pageId } = useParams() as { protocolId: string; projectId: string; pageId: string }

  const errorHandler = useErrorHandler()

  const submitChange = (inputTxt, lang) => {
    const p = ENDPOINTS.TRANSLATION_PAGES.ACTIONS.UPDATE_TRANSLATION_PAGES(
      projectId,
      protocolId,
      pageId,
      blockName,
      fieldKey,
      lang
    )

    return Promise.allSettled([put(p, { text: inputTxt }), Timer(500)])
      .then(async (result) => {
        if (result[0].status === 'fulfilled') {
          await mutate()
          return result[0].value.data?.content[blockName][fieldKey] || ''
        } else {
          return Promise.reject(result[0].reason)
        }
      })
      .catch((error) => {
        errorHandler(error)
        //return Promise.reject(error)
      })
  }

  return (
    <div className=" border px-4 py-4" style={{ background: '#FAFAFA' }}>
      <h6 className="mb-3 fw-bold">{fieldKey}</h6>
      {/*<p className="mb-4 opacity-75">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet architecto ex libero saepe voluptates. Asperiores
      </p>*/}
      <div className="">
        {data.map((item, i) => {
          const txt = item.content[blockName][fieldKey]
          const _id = item._id

          //
          // if the protocol or if the page has not been cloned then we cant edit the page
          //
          const allowEdit = !isActiveProtocol && item.status === RESOURCE_STATUS.DRAFT

          const lang = item.language
          return (
            <Fragment key={_id}>
              <div key={i} className="mb-3 d-flex">
                <div className="bg-primary rounded-3 mt-1 px-2 me-2 " style={{ maxHeight: 23, width: 42 }}>
                  <p className="text-white text-center">{lang}</p>
                </div>
                <SingleField txt={txt} allowEdit={allowEdit} lang={lang} submit={submitChange}></SingleField>
                <div className="p-2">
                  <Popover className="pointer" content={item._id}>
                    <InfoCircleOutlined />
                  </Popover>
                </div>
              </div>
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

const SingleField = ({ txt, lang, submit, allowEdit }) => {
  const inputRef = useRef<InputRef>(null)
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)

  const [inputTxt, setInputTxt] = useState(txt)

  const clickker = () => {
    setEdit(true)
  }

  const onChange = (e) => {
    setDirty(true)
    setInputTxt(e.target.value)
  }

  const handleOnBlur = () => {
    if (!dirty) {
      setEdit(false)
      return
    }
    setDirty(true)
    setInputTxt(inputTxt.replaceAll('\n', ''))
    setLoading(true)
    setEdit(false)
    return submit(inputTxt, lang)
      .then((res) => {
        setDirty(false)
        setLoading(false)
        setInputTxt(res)
      })
      .catch((err) => {
        setInputTxt(txt)
        setLoading(false)
        return Promise.reject(err)
      })
  }

  const onPressEnter = () => {
    inputRef?.current?.blur()
  }

  return (
    <div className="w-100" onClick={clickker}>
      {loading ? (
        <div className="ms-4 pb-3 " style={{ marginBottom: 6 }}>
          <Spinner size={4}></Spinner>
        </div>
      ) : (
        <>
          {allowEdit ? (
            <Input.TextArea
              onPressEnter={onPressEnter}
              className="ps-1"
              onBlur={handleOnBlur}
              onChange={onChange}
              ref={inputRef}
              style={{
                background: edit ? 'white' : 'transparent',
                border: edit ? '1px solid #CFD3D7' : '1px solid #fafafa',
              }}
              placeholder="Click to add text"
              autoSize={{ minRows: 1, maxRows: 5 }}
              value={inputTxt}
            />
          ) : (
            <div className="noselect pointer" style={{ marginLeft: -7, marginTop: 0 }}>
              {/*{inputTxt}*/}
              <Input
                className="noselect "
                style={{
                  background: 'transparent',
                  border: '1px solid #fafafa',
                  color: allowEdit ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.6)',
                }}
                value={inputTxt}
                disabled
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}
