/**
 * Created by @author Søren Tramm
 */
import { UseFormReturn } from 'react-hook-form'
import React, { useCallback, useEffect, useState } from 'react'
import { FormLabel } from '../../components/form/FormLabel'
import { TimePicker } from 'antd'
import dayjs from 'dayjs'

export const ReminderTimeWidget = React.forwardRef(
  (
    {
      data,
      formHook,
      id,
      label,
      placeholder = 'Time',
    }: {
      data: any
      formHook: UseFormReturn
      id: string
      label: string
      placeholder?: string
    },
    ref
  ) => {
    const defaultValue = data?.notifications?.reminders?.endTime
    const format = 'HH:mm'

    const {
      register,
      setValue,
      unregister,
      formState: { errors },
      clearErrors,
    } = formHook

    // Expose reset function to the parent component
    React.useImperativeHandle(ref, () => ({
      reset,
    }))
    const reset = () => {
      setValue(id, undefined)
      setResetValue(true)
    }

    const [resetValue, setResetValue] = useState<boolean>(false)
    const [selectedValue, setSelectedValue] = useState<dayjs.Dayjs>()

    const errorState: any = id.split('.').reduce((acc: any, key: string) => {
      return acc?.[key]
    }, errors)

    const errorMessage: string =
      errorState && errorState.message ? (errorState.message as string) : 'Time for the reminder is required'

    const handleUnregister = useCallback(
      (fields) => {
        unregister(fields)
      },
      [unregister]
    )

    useEffect(() => {
      register(id, { required: true })
      setValue(id, defaultValue)

      // Unregister fields when the component unmounts
      return () => handleUnregister(id)
    }, [id, data, register, handleUnregister, setValue, defaultValue])

    const onChangeHandler = (value: dayjs.Dayjs | null, time: string) => {
      clearErrors()
      if (value) {
        const time = { h: value?.get('h'), m: value?.get('m'), duration: 3 }
        setValue(id, time)
        setSelectedValue(value)
        setResetValue(false)
      }
    }

    /**
     * Limit the selectable minute values to 0, 15, 30, 45
     */
    const disabledTime = useCallback(() => {
      return {
        disabledMinutes: () =>
          Array.from({ length: 60 }, (_, index) => index).filter((minute) => ![0, 15, 30, 45].includes(minute % 60)),
      }
    }, [])

    const getTimeValue = () => {
      if (resetValue) return undefined

      const selectedHour = selectedValue?.get('h')
      const selectedMinute = selectedValue?.get('m')
      const defaultHour = defaultValue?.h
      const defaultMinute = defaultValue?.m

      return selectedValue
        ? dayjs(`${selectedHour}:${selectedMinute}`, format)
        : defaultValue && dayjs(`${defaultHour}:${defaultMinute}`, format)
    }

    return (
      <div className="col-12">
        <FormLabel label={label} />
        <TimePicker
          className="w-100"
          placeholder={placeholder}
          format={format}
          minuteStep={15}
          showNow={false}
          value={getTimeValue()}
          onChange={onChangeHandler}
          disabledTime={disabledTime}
        />
        {errorState && <div className="p-small text-danger">{errorMessage}</div>}
      </div>
    )
  }
)
